<template>
    <div class="wrap">
        <section class="baseinfo">
            <div class="content-container baseinfo-container">
                <div class="info-wrap">
                    <div class="left-box">
                        <div class="tooltip">
                            <img src="@/assets/image/location.png" alt="icon">
                            <div class="info">
                                <div class="com-name">福州永诚招标代理有限公司</div>
                                <div class="location">福建省福州市鼓楼区鼓东街道中山路23号工业品交易中心大楼1101室-1</div>
                            </div>
                        </div>
                    </div>
                    <div class="right-box">
                        <div class="sub-title">ABOUT YONGCHENG</div>
                        <div class="com-name">福州永诚招标代理有限公司</div>
                        <p>福州永诚招标代理有限公司于2015年10月16日经福州市鼓楼区市场监督管理局注册成立，注册资本501万元，具有独立法人资格，与行政机构没有隶属关系和其他利益关系。</p>
                        <p>我公司从事招标代理服务，业务以政府采购代理为核心，向工程招标、项目管理等方向延伸。服务领域涵盖各级政府部门、银行、医院、高等学校、央企和国有企业等社会各行业，具有较强的专业化运营能力。</p>
                        <p>本公司办公场所约518平方米，组织机构完善，职责明确，内设开标大厅、评标室、抽取专家室、监控室、档案室、财务室、样品陈列室等职能部室。办公环境优美、办公条件优越，地处福州市中心区域，交通便利，配套公建及设施设备完善，能够保证各类招标代理活动有序、良好的进行。</p>
                        <p>我们相信，高效的工作效率、丰富的招标代理经验、严密的质量管理体系和雄厚的硬件力量，能为您提供更加优质的招标代理服务。</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="business">
            <div class="content-container business-container">
                <div class="title-wrap">
                    <div class="title">
                        <span class="decoration"></span><span class="first-two">业务</span>范围
                    </div>
                </div>
                <div class="scope">
                    <div class="item" v-for="item in businessScope" :key="item.name">
                        <img class="bg-icon" :src="item.path" alt="icon">
                        <div class="surface">
                            <img class="surface-icon" :src="item.path" alt="icon">
                            <span class="name">{{item.name}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="framework">
            <div class="content-container framework-container">
                <div class="title-wrap">
                    <div class="title">
                        <span class="decoration"></span><span class="first-two">组织</span>架构
                    </div>
                    <p class="tip">公司建设情况：公司现已配备各类专职人员</p>
                </div>
                <div class="framework-content">
                    <div class="avatar">
                        <img src="@/assets/image/avatar.png" alt="avatar">
                    </div>
                    <div class="name">总经理</div>
                    <p class="duty">全面负责本公司日常业务工作及管理，负责业务管理规程的执行；监督检查客户拓展流程、客户管理程序、业务工作流程及其工作要求的执行状况；协调解决有关问题，制定业务策略，不断改进业务工作；负责客户资源分配、员工技能考察。</p>
                    <div class="divider"></div>
                    <div class="grid-box">
                        <div class="dapartment" v-for="item in framework" :key="item.name">
                            <div class="line"></div>
                            <div class="name">{{item.name}}</div>
                            <div class="content" v-for="piece in item.content" :key="piece">
                                <p>{{piece}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="qualification">
            <div class="content-container qualification-container">
                <div class="title-wrap">
                    <div class="title">
                        <span class="decoration"></span><span class="first-two">资质</span>展示
                    </div>
                    <p class="tip">企业相关证件、中国政府采购网、福建省分网登记注册材料</p>
                </div>
                <Carousel :honor="certification"/>
            </div>
        </section>
        <section class="showcase">
            <div class="content-container showcase-container">
                <div class="title-wrap">
                    <div class="title">
                        <span class="decoration"></span><span class="first-two">公司</span>展示
                    </div>
                </div>
                <div class="grid-box">
                    <div class="item" v-for="item in locale" :key="item.name">
                        <el-image :class="['locale']" :src="item.path" lazy :preview-src-list="srcList" :hide-on-click-modal="true" fit="cover" style="width: 285px; height:210px">
                            <template #error>
                                <div class="image-slot">
                                    <i class="el-icon-picture-outline"></i>
                                </div>
                            </template>
                        </el-image>
                        {{item.name}}
                    </div>
                </div>
            </div>
        </section>

    </div>
</template>
<script>
import {
    getCurrentInstance,
    reactive,
    computed,
    toRefs
} from 'vue'
import Carousel from '@/components/Carousel'
export default {
    name: 'intro',
    components: {
        Carousel
    },
    setup() {
        const { proxy } = getCurrentInstance()
        const state = reactive({
            businessScope: [
                {name: '招标代理', id:'biding', path: require('@/assets/image/icon/bidding.png')},
                {name: '政府采购咨询', id:'consult', path: require('@/assets/image/icon/consult.png')},
                {name: '建筑工程规划设计', id:'design', path: require('@/assets/image/icon/design.png')},
                {name: '工程监理', id:'supervisor', path: require('@/assets/image/icon/supervisor.png')},
                {name: '工程造价咨询', id:'cost', path: require('@/assets/image/icon/cost.png')},
                {name: '工程项目管理', id:'manage', path: require('@/assets/image/icon/manage.png')},
            ],
            framework: [
                {name: '财务部', content: [
                    '负责收取招标文件的费用',
                    '供应商保证金的核对',
                    '中标通知书的发放',
                    '中标服务费的收取',
                    '供应商保证金的退还'
                ]},
                {name: '招标部', content: [
                    '负责采购办的集中采购计划书的整理',
                    '招标文件的编写',
                    '负责供应商来电接待',
                    '记录供应商基本信息',
                    '承担招标文件的信息'
                ]},
                {name: '综合部', content: [
                    '服务采购人资源的日常管理工作',
                    '采购人名单及其基本信息的收集、整理',
                    '建立并管理采购人档案和文件资料归档和管理'
                ]},
                {name: '业务部', content: [
                    '负责业务拓展的市场促进工作',
                    '制定业务拓展和业务工作规范、计划',
                    '拟定相应的对策和解决方案',
                    '申请并协调公司各项资源',
                    '考核评价本组业务人员的工作绩效'
                ]}
            ],
            certification: [],
            locale: []
        })


        const srcList = computed(() => {
            let pathArr = []
            state.locale.forEach(item => {
                pathArr.push(item.path)
            })
            return pathArr
        })

        let arr = ['certification', 'locale']
        arr.forEach(type => getImages(type))


        function getImages(type) {
            proxy.$api.image.getImage(type).then(res => {
                state[type] = res.data && res.data.length ? res.data : []
            })
        }
        
        return {
            ...toRefs(state),
            srcList
        }
    }
}
</script>
<style lang="scss" scoped>
    @import "index";
</style>