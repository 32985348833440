<template>
    <div class="container">
        <el-carousel v-if="honor.length" type="card" height="600px" class="my-carousel" :autoplay="false" arrow="never">
            <!-- <div class="arrow-left" @click="prev"></div> -->
            <el-carousel-item v-for="item in honor" :key="item.id">
                <!-- <h3>{{item}}</h3> -->
                <el-image class="honor" :src="item.path" fit="contain" style="height: 100%">
                    <template #error>
                        <div class="image-slot">
                            <img :src="defaultImg" alt="" width="400" height="580" />
                        </div>
                    </template>
                </el-image>
            </el-carousel-item>
            <!-- <div class="arrow-right" @click="next"></div> -->
        </el-carousel>
    </div>
</template>
<script>
import {
    // getCurrentInstance,
    reactive,
    toRefs,
    watch
} from 'vue'
export default {
    name: 'carousel',
    props: {
        honor: {
            type: Array,
            require: true,
        }
    },
    setup(props) {
        const state = reactive({
            honor: [],
            defaultImg: require('../../assets/image/carouselImg.png')
        })

        watch(() => props.honor, (newVal) => {
            state.honor = newVal
        })

        return {
            ...toRefs(state)
        }
    }
}
</script>
<style lang="scss" scoped>
    @import "index";
</style>